<template>
   <div class="smart-wrap container-blog">
      <div class="col-md-4" style="padding: 20px; " v-for="(art, index) in list" :key="index">
         <a :href="base+'/'+art.path" target="_blank" class="content">
            <img :src="art.inputImages[0].url" alt="">
            <h4 >{{art.headline}}</h4>
            <span>{{art.publishDate}}</span>
            <p>{{art.summary}}</p>
            <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><path fill="#5e5e5e" d="M8.7 17.3q-.275-.275-.275-.7q0-.425.275-.7l3.9-3.9l-3.9-3.9q-.275-.275-.275-.7q0-.425.275-.7q.275-.275.7-.275q.425 0 .7.275l4.6 4.6q.15.15.213.325q.062.175.062.375t-.062.375q-.063.175-.213.325l-4.6 4.6q-.275.275-.7.275q-.425 0-.7-.275Z"/></svg>
         </a>
      </div>
   </div>
</template>
<script>
//Tools
import { API, Storage, Auth } from "aws-amplify";
import Vuex from "vuex";
import Swal from "sweetalert2";
import { calendar, loading, popUp, formatData } from "../../store/tools";

//import html2pdf from 'html2pdf'
//Components
import PaperDisplayer from "../../components/papers/PaperDisplayer.vue";
import PaperLayout from "../../components/papers/PaperLayout.vue";
import UiButton from "../../components/ui/UiButton.vue";
//GRAPHQL
import { smd_updateSmartRecord } from "../../graphql/mutations";
import {
   smd_listEntityObj,
} from "../../graphql/queries";

export default {
   name: "checkout",
   components: {
      PaperDisplayer,
      UiButton,
      PaperLayout,
   },
   data() {
      return {
         list: null,
         base:'',
      };
   },
   created() {
      console.clear()
      this.setUserWeb()
      this.base = parent.document.location.origin
   },
   methods: {
      ...Vuex.mapActions(["getPublicOrganization", "getPublicOrganizationData"]),
      ...Vuex.mapMutations([
         "setStatusReload",
         "setPublicOrganizationData",
         "setPublicOrgPay",
         "setPublicOrganization",
         "setContactSK",
         "setWebSite",
         "SetUser",
      ]),
      async setUserWeb(){
         loading('show')
         Auth.signIn('smd_webuser', '383@JDU45ss#')
         .then((user) => { 
            this.getBlog()
         })
         .catch((err) => popUp(err, 'response', 'err'));
         
      },

      async getBlog(){
         await this.getPublicOrganization()//Alias
         let pullData = ''
         try {
            pullData = await API.graphql({
               query: smd_listEntityObj,
               variables: {
                  active: '1',
                  PK: this.publicOrganization,
                  shortEntity: 'BLO',
                  pageSize: 0,
                  index: 3,
                  group: 'Active',
               }
            })
            pullData = pullData.data.smd_listEntityObj
            let list = JSON.parse(pullData.data)
            for (let index = 0; index < list.length; index++) {
               const att = list[index].attributes
               console.log(list[index]);
               for (const key in att) {
                  try {
                     if (att[key].attributeType == 'L') {
                        list[index][key] = JSON.parse(att[key].attributeValue)
                     } else {
                        list[index][key] = att[key].attributeValue
                     }
                  } catch (error) {
                     console.log(key ,att[key].attributeValue);
                  }
               }
            }
            this.list = list
            loading('hidde')
         } catch (error) {
            console.log(error);
            popUp(error, 'cach', 'err')
         }
      },
      formatData(format, data) {
         return formatData(format, data);
      },
      //View
   },
   computed: {
      ...Vuex.mapState([
         "publicOrganization",
         "publicOrganizationData",
         "publicOrganization",
         "reload",
         "user",
         "publicOrgPay",
         "dataUser",
         "quiz",
         "organizationData",
         "calAlert",
      ]),
   },
};
</script>
<style>
@import url('https://fonts.googleapis.com/css2?family=Kdam+Thmor+Pro&family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
</style>
<style lang="less">
   .hover(){
      -webkit-transition: all 0.3s ease 0s;
      -moz-transition: all 0.3s ease 0s;
      -ms-transition: all 0.3s ease 0s;
      -o-transition: all 0.3s ease 0s;
      transition: all 0.3s ease 0s;
   }
   .container-in{
      max-width: 1200px;
   }
   .container-blog{
      .content{
         display: block;
         border-radius: 20px;
         background: #ffffff;
         text-align: left;
         border-radius: 0 0 50px 0;
         position: relative;
         color: #404040;
         .hover;
         img{
            width: 100%;
         }
         h4{
            font-family: 'Raleway', sans-serif;
            padding: 20px 20px 0px;
            font-size: 22px;
            font-weight: 700;
         }
         p{
            font-family: 'Montserrat', sans-serif;
            padding: 20px 20px 50px;
         }
         span{
            font-family: 'Montserrat', sans-serif;
            font-style: italic;
            text-align: right;
            margin: 10px 20px 0;
            display: block;
            font-size: 14px;
         }
         svg{
            width: 40px;
            height: 40px;
            background: #ececec;
            border-radius: 50%;
            display: block;
            position: absolute;
            bottom: 25px;
            right: 25px;
            opacity: 0;
            .hover;
         }
         &:hover{
            box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
            .hover;
            svg{
               .hover;
               opacity: 1;
            }
         }
      }
   }
</style>
